/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  word-wrap: break-word;
}

table {
  width: 100%;
  margin: 1em auto;
  border: 1px solid #cacaca;
  border-collapse: collapse;
  text-align: left;
}

table td {
  padding: 5px 10px;
  border: 1px solid #cacaca;
}

table th {
  padding: 5px 10px;
  border: 1px solid #cacaca;
  background: #e6e6e6;
}

/**
 * Code formatting
 */
pre,
code {
  font-family: $monospace-font-family;
  font-size: 13px;
  /* border: 1px solid $grey-color-light; */
  /* border-radius: 0; */
  background-color: #f0f0f0;
}

pre {
  overflow-x: auto;
}

div.highlight > pre.highlight {
  padding: 1em;
}

/**
 Code tables
 */

table.rouge-table { 
  margin: 0px;
  line-height: 20px;
  border-collapse: collapse;
  border: 0px;
}

table.rouge-table td {
  border: 0px;
  padding: 0px;
}

table.rouge-table td.code {
  width: 100%;
}

table.rouge-table td.gutter {
}

table.rouge-table td.gutter pre.lineno {
  background-color: $code-chrome;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
}

table.rouge-table td.code pre {
  padding-left: 4px;
}

table.rouge-table td.code pre {
  background-image: linear-gradient(180deg, $code-odd-line 0, $code-odd-line 20px, $code-even-line 0);
  background-size: 100% 40px;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, /* pre, */
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  padding-left: 4px;
  font-size: $small-font-size;
}

figure.highlight > figcaption {
  background-color: $code-chrome;
  font-family: $monospace-font-family;
  font-size: 13px;
}

figure.highlight {
  border: 0px solid $grey-color-light;
  border-radius: 0;
  padding: 0px;
}

figure.highlight > pre {
  overflow-x: auto;
  padding: 1em;
}

figure.highlight > pre.with_line_numbers {
  overflow-x: auto;
  padding: 0em;
}

/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;

  code {
    font-size: inherit;
  }
}



/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 10%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}



/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }

  pre, code {
    color: $text-color;
  }
}



/**
 * Rules
 */
hr {
  height: 4px;
  margin: $spacing-unit / 2 0;
  border: 0;
  background-color: $grey-color-light;
}




/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

// Based on https://www.the-art-of-web.com/css/format-dl/

dl {
  display: flex;
  flex-flow: row wrap;
}
dt {
  font-weight: 700;
  flex-basis: 20%;
  padding-right: 1em;
  text-align: right;
  border-right: 1px solid;
  margin-bottom: 0.5em;
}
dd {
  flex-basis: 70%;
  flex-grow: 1;
  padding-left: 1em;
  margin-bottom: 0.5em;
}

// Define defaults for each variable.
$base-font-family: "Source Sans Pro", sans-serif !default;
$monospace-font-family: "Fira Mono", monospace !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2568ba !default;

$grey-color:       #757575 !default;
$grey-color-light: lighten($grey-color, 45%) !default;
$grey-color-dark:  darken($grey-color, 20%) !default;

$code-chrome: #ddeeff;
$code-odd-line: #eeeeee;
$code-even-line: #fefefe;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;



// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials.
@import
  "whiteglass/base",
  "whiteglass/layout",
  "whiteglass/syntax-highlighting"
;
